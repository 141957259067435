import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Htext = ({ children, className }: Props) => {
  return (
    <h2
      className={twMerge(
        "text-gray-700 opacity-90 font-sans tracking-wider text-5xl font-bold text-center",
        className
      )}
    >
      {children}
    </h2>
  );
};

export default Htext;
