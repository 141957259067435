import { useState, useEffect } from "react";
import { SelectedPage } from "../../shared/types";
import { motion } from "framer-motion";
import { ServiceType } from "../../shared/types";
import Service from "./Service";
import StrategicTechnologyConsulting from "../../assets/icons/strategicTechnologyConsulting.png";
import ElectronicDesignReview from "../../assets/icons/electronicDesignReview.png";
import ProjectManagement from "../../assets/icons/projectManagement.png";
import EndToEndProductDevelopment from "../../assets/icons/endToEndProductDevelopment.png";
import SystemEngineering from "../../assets/icons/systemEngineering.png";
import Htext from "../../shared/Htext";
import { useTranslation } from "react-i18next";

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const Services = ({ setSelectedPage }: Props) => {
  const { t, i18n } = useTranslation("translations");

  const englishStyle = "text-left";
  const hebrewStyle = "text-right";

  const [currentStyle, setCurrentStyle] = useState(englishStyle);
  const [direction, setDirection] = useState("ltr");

  const services: Array<ServiceType> = [
    {
      title: t("services.systemEngineering.title"),
      //add new line
      description: <p>{t("services.systemEngineering.description")}</p>,
      icon: (
        <img
          className="h-16 w-16"
          src={SystemEngineering}
          alt={SystemEngineering}
        />
      ),
    },
    {
      title: t("services.strategicTechnologyConsulting.title"),
      //in list
      description: (
        <p>{t("services.strategicTechnologyConsulting.description")}</p>
      ),
      icon: (
        <img
          className="h-16 w-16"
          src={StrategicTechnologyConsulting}
          alt={StrategicTechnologyConsulting}
        />
      ),
    },
    {
      title: t("services.electronicDesignReview.title"),
      description: <p>{t("services.electronicDesignReview.description")}</p>,
      icon: (
        <img
          className="h-16 w-16"
          src={ElectronicDesignReview}
          alt={ElectronicDesignReview}
        />
      ),
    },
    {
      title: t("services.endToEndProductDevelopment.title"),
      description: (
        <p>{t("services.endToEndProductDevelopment.description")}</p>
      ),
      icon: (
        <img
          className="h-16 w-16"
          src={EndToEndProductDevelopment}
          alt={EndToEndProductDevelopment}
        />
      ),
    },
    {
      title: t("services.projectManagement.title"),
      //in list
      description: (
        <ul dir={direction} className={`w-[80%] list-disc ${currentStyle}`}>
          <li>{t("services.projectManagement.description.bullet1")}</li>
          <li>{t("services.projectManagement.description.bullet2")}</li>
          <li>{t("services.projectManagement.description.bullet3")}</li>
          <li>{t("services.projectManagement.description.bullet4")}</li>
          <li>{t("services.projectManagement.description.bullet5")}</li>
        </ul>
      ),
      icon: (
        <img
          className="h-16 w-16"
          src={ProjectManagement}
          alt={ProjectManagement}
        />
      ),
    },
  ];

  useEffect(() => {
    if (i18n.language === "he") {
      setCurrentStyle(hebrewStyle);
      setDirection("rtl");
    } else {
      setCurrentStyle(englishStyle);
      setDirection("ltr");
    }
  }, [i18n.language]);

  return (
    <section id="services" className="bg-gray-100 mx-auto min-h-full py-20">
      <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.Services)}
      >
        {/* HEADER */}
        <motion.div
          className="md:my-5 md:w-3/5 text-black w-5/6 mx-auto"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <Htext className={`${currentStyle}`}>{t("services.title")}</Htext>
        </motion.div>

        {/* SERVICES */}
        <div className="flex flex-wrap items-center justify-center gap-8 mt-5">
          {services.map((service) => (
            <Service
              key={service.title}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Services;
