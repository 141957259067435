import { useState } from "react";
import NavBar from "./components/navbar/NavBar";
import { SelectedPage } from "./shared/types";
import Home from "./components/home/Home";
import AboutUs from "./components/aboutUs/AboutUs";
import Services from "./components/services/Services";
import Expertise from "./components/expertise/Expertise";
import Partners from "./components/partners/Partners";
import Footer from "./components/footer/Footer";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const [selectedPage, setSelectedPage] = useState<SelectedPage>(
    SelectedPage.Home
  );

  return (
    <div className="app">
      <header className="sticky top-0 z-30">
        <NavBar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      </header>
      <Home setSelectedPage={setSelectedPage} />
      <AboutUs setSelectedPage={setSelectedPage} />
      <Expertise setSelectedPage={setSelectedPage} />
      <Partners setSelectedPage={setSelectedPage} />
      <Services setSelectedPage={setSelectedPage} />
      <Footer />
    </div>
  );
}

export default App;
