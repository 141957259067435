import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from "../locales/en/translation.json";
import translationsInHeb from "../locales/he/translation.json";

//translations
const resources = {
  en: {
    translations: translationsInEng,
  },
  he: {
    translations: translationsInHeb,
  },
};

i18next.use(initReactI18next).init({
  resources: resources,
  lng: 'en',
  debug: true,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  ns: "translation",
  defaultNS: "translation"
})

export default i18next;
