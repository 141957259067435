import { motion } from "framer-motion";

type Props = {
  skill: string;
  description: JSX.Element;
  image: string;
};

const Skill = ({ skill, description, image }: Props) => {
  const overlayStyles = `p-5 absolute z-20 flex h-[280px] w-[350px] flex-col items-center 
  justify-center whitespace-normal text-center text-white 
  opacity-0 transition duration-500 hover:opacity-80 rounded-md`;

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0 }}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
      }}
      className="relative"
    >
      <div className={`${overlayStyles} bg-blue-500 gap-10`}>
        <h4 className="text-3xl font-sans font-semibold">{skill}</h4>
        {description}
      </div>
      <img
        className="h-[280px] w-[350px] rounded-md"
        src={image}
        alt={`${image}`}
      />
    </motion.div>
  );
};

export default Skill;
