export enum SelectedPage {
    Home = "home",
    Services = "services",
    Expertise = "expertise",
    Partners = "partners",
    ContactUs = "contactus",
    AboutUs = "aboutus"
  }
  
  export interface ServiceType {
    icon: JSX.Element;
    title: string;
    description: JSX.Element;
  }
  
  export interface SkillType {
    skill: string;
    description: JSX.Element;
    image: string;
  }
  
  export interface PartnerType {
    image: string;
  }