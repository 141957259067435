import { SelectedPage, SkillType } from "../../shared/types";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import RFcommunication from "../../assets/RFcommunication.jpg";
import HomeLandSecurity from "../../assets/HomeLand Security.jpg";
import MultidisciplinarySystems from "../../assets/Maltidisciplinary systems.jpg";
import CyberSecurity from "../../assets/Cyber Security.jpg";
import FailSafeSystems from "../../assets/FailSafe systems.jpg";
import Htext from "../../shared/Htext";
import Skill from "./Skill";
import { useTranslation } from "react-i18next";

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const Expertise = ({ setSelectedPage }: Props) => {
  const { t, i18n } = useTranslation("translations");

  const englishStyle = "text-left";
  const hebrewStyle = "text-right";

  const [currentStyle, setCurrentStyle] = useState(englishStyle);
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    if (i18n.language === "he") {
      setCurrentStyle(hebrewStyle);
      setDirection("rtl");
    } else {
      setCurrentStyle(englishStyle);
      setDirection("ltr");
    }
  }, [i18n.language]);

  const skills: Array<SkillType> = [
    {
      skill: t("expertise.RFcommunication.title"),
      //list
      description: (
        <ul dir={direction} className={`list-disc ${currentStyle}`}>
          <li>{t("expertise.RFcommunication.description.bullet1")}</li>
          <li>{t("expertise.RFcommunication.description.bullet2")}</li>
          <li>{t("expertise.RFcommunication.description.bullet3")}</li>
          <li>{t("expertise.RFcommunication.description.bullet4")}</li>
          <li>{t("expertise.RFcommunication.description.bullet5")}</li>
        </ul>
      ),
      image: RFcommunication,
    },
    {
      skill: t("expertise.homelandSecurity.title"),
      //list
      description: (
        <ul dir={direction} className={`list-disc ${currentStyle} w-[60%]`}>
          <li>{t("expertise.homelandSecurity.description.bullet1")}</li>
          <li>{t("expertise.homelandSecurity.description.bullet2")}</li>
          <li>{t("expertise.homelandSecurity.description.bullet3")}</li>
          <li>{t("expertise.homelandSecurity.description.bullet4")}</li>
        </ul>
      ),
      image: HomeLandSecurity,
    },
    {
      skill: t("expertise.multidisciplinarySystems.title"),
      description: (
        <ul dir={direction} className={`list-disc ${currentStyle}`}>
          <li>{t("expertise.multidisciplinarySystems.description.bullet1")}</li>
          <li>{t("expertise.multidisciplinarySystems.description.bullet2")}</li>
          <li>{t("expertise.multidisciplinarySystems.description.bullet3")}</li>
          <li>{t("expertise.multidisciplinarySystems.description.bullet4")}</li>
          <li>{t("expertise.multidisciplinarySystems.description.bullet5")}</li>
        </ul>
      ),
      image: MultidisciplinarySystems,
    },
    {
      skill: t("expertise.cyberSecurity.title"),
      description: <p>{t("expertise.cyberSecurity.description")}</p>,
      image: CyberSecurity,
    },
    {
      skill: t("expertise.failSafeSystems.title"),
      description: <p>{t("expertise.failSafeSystems.description")}</p>,
      image: FailSafeSystems,
    },
  ];

  return (
    <section
      id="expertise"
      className="
        mx-auto 
        min-h-full 
        py-20
        bg-gray-100
      "
    >
      <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.Expertise)}
      >
        {/* HEADER */}
        <motion.div
          className="md:my-5 md:w-3/5 w-5/6 mx-auto"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <Htext className={`${currentStyle}`}>{t("expertise.title")}</Htext>
        </motion.div>

        {/* SKILLS */}
        <div
          className="
            flex 
            flex-wrap 
            justify-center 
            gap-28 
            pt-10
            "
        >
          {skills.map((skill: SkillType) => (
            <Skill
              key={skill.skill}
              skill={skill.skill}
              description={skill.description}
              image={skill.image}
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Expertise;
