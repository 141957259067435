import { SelectedPage } from "../../shared/types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { twMerge } from "tailwind-merge";

type Props = {
  page: string;
  name: string;
  selectedPage: SelectedPage;
  className?: string;
  setSelectedPage: (value: SelectedPage) => void;
};

const ScrollLink = ({
  page,
  name,
  selectedPage,
  setSelectedPage,
  className,
}: Props) => {
  const lowerCasePage = name
    .toLocaleLowerCase()
    .replace(/ /g, "") as SelectedPage;

  return (
    <AnchorLink
      className={twMerge(
        `${
          selectedPage === lowerCasePage ? "text-purple-500" : ""
        } transition duration-500 hover:text-purple-500 cursor-pointer`,
        className
      )}
      onClick={() => setSelectedPage(lowerCasePage)}
      href={`#${lowerCasePage}`}
    >
      {page}
    </AnchorLink>
  );
};

export default ScrollLink;
