import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef, FormEvent } from "react";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";

const Footer = () => {
  const { t, i18n } = useTranslation("translations");

  const inputStyle =
    "text-white outline-none placeholder:text-gray-300 bg-transparent border-b-2 border-grey-300 w-[45%] mt-6 ";

  const textareaStyle =
    "text-white outline-none placeholder:text-gray-300 bg-transparent border-b-2 border-grey-300 w-full my-6";

  const englishStyle = "text-left";
  const hebrewStyle = "text-right";

  const [currentStyle, setCurrentStyle] = useState(englishStyle);

  useEffect(() => {
    if (i18n.language === "he") {
      setCurrentStyle(hebrewStyle);
    } else {
      setCurrentStyle(englishStyle);
    }
  }, [i18n.language]);

  const form = useRef<HTMLFormElement | null>(null);

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_khhec27",
          "template_z2af5og",
          form.current,
          "q7tO8HW3TWvH8Nmv1"
        )
        .then(
          (result: EmailJSResponseStatus) => {
            console.log(result.text);
            toast.success("We received your email!", {
              position: "bottom-right",
            });
          },
          (error: EmailJSResponseStatus) => {
            console.log(error.text);
            toast.error("Something went wrong...", {
              position: "bottom-right",
            });
          }
        );
    }
  };

  return (
    <footer className="bg-purple-950 pb-10">
      <div className="w-full">
        <img className="w-full" src="Logo2.png" />
      </div>
      <div className="md:justify-evenly mx-auto w-full md:flex text-white">
        <div className="p-10 mt-16 basis-2/3">
          <h1 className={`${currentStyle} uppercase font-semibold text-4xl`}>
            {t("footer.title")}
          </h1>
          <form
            ref={form}
            className="flex flex-col items-center"
            onSubmit={sendEmail}
          >
            <div className="flex flex-wrap justify-between w-full">
              <input
                className={`${inputStyle} ${currentStyle}`}
                placeholder={`${t("footer.inputs.name")}`}
                type="text"
                name="user_name"
                required
              />
              <input
                className={`${inputStyle} ${currentStyle}`}
                placeholder={`${t("footer.inputs.phone")}`}
                type="tel"
                name="phone"
                required
              />
            </div>
            <div className="flex flex-wrap justify-between w-full">
              <input
                className={`${inputStyle} ${currentStyle}`}
                placeholder={`${t("footer.inputs.email")}`}
                type="email"
                name="user_email"
                required
              />
              <input
                className={`${inputStyle} ${currentStyle}`}
                placeholder={`${t("footer.inputs.company")}`}
                type="text"
                name="company"
                required
              />
            </div>
            <textarea
              className={`${textareaStyle} ${currentStyle}`}
              placeholder={`${t("footer.inputs.message")}`}
              name="message"
              required
            />
            <button
              className="
              border-2
              w-fit
              px-8
              py-2
              rounded-sm
              text-lg
              uppercase
              hover:bg-white
              hover:text-purple-950
              hover:font-bold
              transition
              duration-300
            "
              type="submit"
            >
              {t("footer.button")}
            </button>
          </form>
        </div>
        <div className="mt-16 basis-1/3 flex flex-col items-center justify-center">
          <div>
            <h4 className="font-bold text-3xl mb-10">
              {t("footer.contactUs.title")}
            </h4>
            <p className={`${currentStyle}`}>{t("footer.contactUs.p1")}</p>
            <p className={`${currentStyle} mt-6`}>{t("footer.contactUs.p2")}</p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </footer>
  );
};

export default Footer;
