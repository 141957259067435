import { motion } from "framer-motion";

type Props = {
  icon: JSX.Element;
  title: string;
  description: JSX.Element;
};

const Service = ({ icon, title, description }: Props) => {
  return (
    <motion.div
      className="
        w-[350px]
        h-[360px]
        flex
        flex-col
        rounded-sm
        mt-5
        shadow-md
        px-5
        py-10
        text-center
        items-center
        bg-gray-50
        text-black
        transition-all
        max-sm:shadow-lg
        max-sm:shadow-purple-500
        hover:shadow-lg
        hover:shadow-purple-500
    "
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0 }}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
      }}
    >
      <div className="flex justify-center">{icon}</div>

      <h4 className="text-2xl py-5">{title}</h4>

      <div className="flex text-gray-600 text-md justify-center">
        {description}
      </div>
    </motion.div>
  );
};

export default Service;
