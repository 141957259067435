import { useState, useEffect } from "react";
import { SelectedPage } from "../../shared/types";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Htext from "../../shared/Htext";
import Arik from "../../assets/arik.png";

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const AboutUs = ({ setSelectedPage }: Props) => {
  const { t, i18n } = useTranslation("translations");

  const englishStyle = "text-left";
  const hebrewStyle = "text-right";

  const [currentStyle, setCurrentStyle] = useState(englishStyle);
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    if (i18n.language === "he") {
      setCurrentStyle(hebrewStyle);
      setDirection("rtl");
    } else {
      setCurrentStyle(englishStyle);
      setDirection("ltr");
    }
  }, [i18n.language]);

  return (
    <section
      id="aboutus"
      className="
        mx-auto 
        my-auto
        min-h-full 
        text-black
        py-20
        bg-gray-50"
    >
      <motion.div onViewportEnter={() => setSelectedPage(SelectedPage.AboutUs)}>
        {/* HEADER */}
        <motion.div className="md:my-5 md:w-3/5 w-5/6 mx-auto">
          <Htext className={`${currentStyle} ${direction}`}>
            {t("aboutUs.title")}
          </Htext>
        </motion.div>

        <div className="md:flex mx-auto w-5/6 gap-10">
          <div
            className={`flex flex-col md:w-4/6 p-10 text-lg justify-center ${currentStyle} ${direction}`}
          >
            <h3 className="font-bold text-2xl pb-4 text-purple-800">
              {t("aboutUs.subTitle")}
            </h3>
            <p>{t("aboutUs.description")}</p>
            <p className="mt-5 font-semibold">{t("aboutUs.bottomTitle")}</p>
          </div>

          {/* IMAGE */}
          <div className="flex flex-col md:w-2/6 w-1/2 justify-center items-center mx-auto">
            <img src={Arik} alt={Arik} />
            <h3 className="text-xl font-semibold">{t("aboutUs.name")}</h3>
            <p className="text-gray-500">{t("aboutUs.jobDescription")}</p>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default AboutUs;
