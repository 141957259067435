import { useState, useEffect } from "react";
import { SelectedPage } from "../../shared/types";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const Home = ({ setSelectedPage }: Props) => {
  const { t, i18n } = useTranslation("translations");

  const englishStyle = "text-left";
  const hebrewStyle = "text-right";

  const [currentStyle, setCurrentStyle] = useState(englishStyle);

  useEffect(() => {
    if (i18n.language === "he") {
      setCurrentStyle(hebrewStyle);
    } else {
      setCurrentStyle(englishStyle);
    }
  }, [i18n.language]);

  return (
    <section id="home">
      <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.Home)}
        className="
          flex
          flex-col
          mx-auto
          w-5/6
          items-center
          justify-center
          h-full
        "
      >
        {/* MAIN HEADER */}
        <div className="z-10 md:basis-3/5 flex items-center">
          {/* HEADINGS */}
          <motion.div
            className="md:-mt-30"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <h2 className={`text-3xl font-sans ${currentStyle}`}>
              {t("home.subTitle")}
            </h2>
            <h1 className={`text-6xl font-sans font-semibold ${currentStyle}`}>
              {t("home.title")}
            </h1>
            <p className={`mt-4 text-xl font-semibold ${currentStyle}`}>
              {t("home.description")}
            </p>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default Home;
