import { SelectedPage } from "../../shared/types";
import { motion } from "framer-motion";
import Cust2Mate from "../../assets/companies/cust2mate.png";
import GorenKidon from "../../assets/companies/goren-kidon.png";
import Hex1101 from "../../assets/companies/hex1011.jpg";
import AcroMatis from "../../assets/companies/AcroMatis.jpg";
import Newcept from "../../assets/companies/Newcept.jpg";
import OriginGroup from "../../assets/companies/origin-group.jpg";
import { PartnerType } from "../../shared/types";
import Htext from "../../shared/Htext";
import Partner from "./Partner";
import { useTranslation } from "react-i18next";

const partners: Array<PartnerType> = [
  {
    image: Cust2Mate,
  },
  {
    image: GorenKidon,
  },
  {
    image: Hex1101,
  },
  {
    image: AcroMatis,
  },
  {
    image: Newcept,
  },
  {
    image: OriginGroup,
  },
];

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const Partners = ({ setSelectedPage }: Props) => {
  const { t } = useTranslation("translations");
  return (
    <section id="partners" className="mx-auto bg-gray-50 py-16 px-4">
      <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.Partners)}
        className="flex flex-col md:flex-row"
      >
        {/* HEADER */}
        <motion.div
          className="flex flex-col gap-3 md:w-3/5 text-black w-5/6 mx-auto py-10 items-center justify-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <Htext>{t("partnerships.title")}</Htext>
          <h2 className="text-xl font-semibold">
            {t("partnerships.description")}
          </h2>
        </motion.div>

        {/* PARTNERS */}
        <div
          className="
          flex
          flex-wrap
          pt-10
          mx-auto
          "
        >
          {partners.map((partner) => (
            <Partner key={partner.image} image={partner.image} />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Partners;
