import { SelectedPage } from "../../shared/types";
import ScrollLink from "./ScrollLink";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useState, useEffect } from "react";
import { Bars3Icon, XCircleIcon } from "@heroicons/react/24/outline";
import Israel from "../../assets/languages/israel.png";
import USA from "../../assets/languages/usa.png";
import i18next from "../../i18n/index";
import { useTranslation } from "react-i18next";

type Props = {
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
};

const NavBar = ({ selectedPage, setSelectedPage }: Props) => {
  const { t, i18n } = useTranslation("translations");

  const isAboveMediaQuery = useMediaQuery("(min-width: 764px)");
  const [isMenuToggled, setIsMenuToggled] = useState(false);

  const handleHebrew = () => {
    i18next.changeLanguage("he");
  };

  const handleEnglish = () => {
    i18next.changeLanguage("en");
  };

  const englishStyle = "flex-row";
  const hebrewStyle = "flex-row-reverse";

  const [currentStyle, setCurrentStyle] = useState(englishStyle);

  useEffect(() => {
    if (i18n.language === "he") {
      setCurrentStyle(hebrewStyle);
    } else {
      setCurrentStyle(englishStyle);
    }
  }, [i18n.language]);

  return (
    <nav>
      <div
        className="
        flex 
        items-center 
        w-full 
        "
      >
        <div
          className={`
          bg-white
          text-black
          w-full
          flex 
          justify-between
          mx-auto
          px-4
          py-2
        `}
        >
          <div className="w-[10%] h-[100%]">
            <img src="Logo.jpg" />
          </div>
          {isAboveMediaQuery ? (
            <div
              className={`
            flex 
            justify-between 
            items-center 
            gap-8
            text-sm
          `}
            >
              <div className={`${currentStyle} flex gap-8`}>
                <ScrollLink
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  page={t("navbar.home")}
                  name="Home"
                />
                <ScrollLink
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  page={t("navbar.services")}
                  name="Services"
                />
                <ScrollLink
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  page={t("navbar.expertise")}
                  name="Expertise"
                />
              </div>
              <img
                className="h-5 w-5 cursor-pointer"
                src={Israel}
                alt={Israel}
                onClick={handleHebrew}
              />
              <img
                className="h-5 w-5 cursor-pointer"
                src={USA}
                alt={USA}
                onClick={handleEnglish}
              />
            </div>
          ) : (
            <div className="flex items-center">
              <Bars3Icon
                className={`h-6 w-6 cursor-pointe`}
                onClick={() => setIsMenuToggled(true)}
              />
            </div>
          )}
        </div>
      </div>

      {/* SIDE MENU */}
      {isMenuToggled && !isAboveMediaQuery && (
        <div
          className="
          bg-white 
            w-[300px] 
            h-full
            fixed
            right-0
            bottom-0
            z-40
            "
        >
          <div className="flex justify-between p-5 cursor-pointer">
            <div className="flex gap-3">
              <img
                className="h-5 w-5 cursor-pointer"
                src={Israel}
                alt={Israel}
                onClick={handleHebrew}
              />
              <img
                className="h-5 w-5 cursor-pointer"
                src={USA}
                alt={USA}
                onClick={handleEnglish}
              />
            </div>
            <XCircleIcon
              onClick={() => setIsMenuToggled(false)}
              className=" h-7 w-7 text-black"
            />
          </div>
          <div
            className="
            flex
            flex-col
            gap-10
            items-center
            pt-5
            text-black
          "
          >
            <ScrollLink
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              page={t("navbar.home")}
              name="Home"
            />
            <ScrollLink
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              page={t("navbar.services")}
              name="Services"
            />
            <ScrollLink
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              page={t("navbar.expertise")}
              name="Expertise"
            />
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
